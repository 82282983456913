import CellsTemplate from '../template';
import eventManager from './events';
import Constants from '../constants';
/**
 * @typedef Spec
 * @type {object}
 * @property {string} tagName  a name of a tag
 * @property {HTMLElement} node element of html
 */

const { bindingCodes, externalEventsCodes } = Constants;
/**
 * 
 *  @class CellsManagerTemplate
 */
export default class CellsManagerTemplate {
  cache = {};
  templates = {};
  selected = '';
  locations = [];
  size = 0;
  fixedTemplates = ['__cross'];

  /**
   * @param  {Object} config
   */
  constructor(config = {}) {
    const persistentPages = config.persistentPages || [];

    this.viewLimit = (config.viewLimit && config.viewLimit >= 1 ? config.viewLimit : 3);
    this.fixedTemplates = this.fixedTemplates.concat(persistentPages);
    this.maxSize = this.viewLimit + this.fixedTemplates.length;
  }

  /**
   * @param  {String} name template name
   * @param  {Spec} spec
   * @param  {Boolean} isPrerendering indicate if the page is being pre-rendering
   * @return {CellsTemplate}
   */
  createTemplate(name, spec, isPrerendering = false) {
    let template = this.get(name);

    if (!template) {
      template = this._createCellsTemplate(name, spec);
      this._storeTemplate(name, template, isPrerendering);
    }

    return template;
  }
  /**
   * @param  {String} name template name
   * @param  {Spec} spec
   * @returns {CellsTemplate} 
   */
  _createCellsTemplate(name, spec) {
    const cellsTemplate = new CellsTemplate(spec);
    const cellsTemplateConfig = this._createCellsTemplateConfig(name);

    cellsTemplate.config(cellsTemplateConfig);
    return cellsTemplate;
  }
  /**
   * @param  {String} name template name
   */
  _createCellsTemplateConfig(name) {
    return {
      name: name,
      template: {
        id: this.computeTemplateId(name),
        name: name
      }
    };
  }

  /**
   * Store given template on memory.
   *
   * @param  {String}               name     template name
   * @param  {CellsBridgeTemplate}  template cells bridge template object
   * @param  {Boolean}              isPrerendering indicate if the page is being pre-rendering
   */
  _storeTemplate(name, template, isPrerendering) {
    const { node } = template;

    if (isPrerendering) {
      const templateIsNotInFixedTemplates = this.fixedTemplates.indexOf(name) === -1;
      if (templateIsNotInFixedTemplates) {
        this.fixedTemplates.push(name);
        this.maxSize++;
        const cacheRanOutOfSpaceForNotFixedTemplates = this.maxSize === this.fixedTemplates.length;
        if (cacheRanOutOfSpaceForNotFixedTemplates) {
          this.maxSize += this.viewLimit;
        }
      }
    }

    if (this.size >= this.maxSize) {
      const olderTemplateToDeallocate = this._getOlderRemovableTemplate();
      if (olderTemplateToDeallocate) {
        this.removeTemplate(olderTemplateToDeallocate);
      } else {
        console.warn('No space left in template cache for template ', name);
      }
    }

    this.locations.push(name);
    this.cache[name] = template;
    this.templates[name] = node;
    this.size++;
  }
  /**
   * @returns  {String} 
   */
  _getOlderRemovableTemplate() {
    let found = false;
    let olderRemovableTemplate;

    for(let i=0; !found && i < this.locations.length; i++) {
      const isNotPersistantPage = this.fixedTemplates.indexOf(this.locations[i]) == -1;

      if (isNotPersistantPage) {
        olderRemovableTemplate = this.locations[i];
        found = true;
      }
    }

    return olderRemovableTemplate;
  }
  /**
   * @param  {String} name template name
   * @returns {CellsTemplate} 
   */
  get(name) {
    return this.cache[name];
  }
  /**
   * @param  {String} name template name
   * @returns {HTMLElement} 
   */
  getNode(name) {
    return this.templates[name];
  }
  /**
   * @param  {String} name template name
   * @returns {String} 
   */
  parseTemplateName(name) {
    return name;
  }
  /**
   * @param  {String} name template name
   * @returns {String} 
   */
  computeTemplateId(name) {
    return 'cells-template-' + name.replace(/\./g, '-');
  }
  
  /**
   * @param  {String} name template name
   * @param  {bridgeChannelManager} bridgeChannelManager=undefined manager of templates
   * @param  {String} binding='always' type of binding
   * @param  {Object} ctx={}
   * @fires  template-transition-end
   */
  select(name, bridgeChannelManager = undefined, binding = 'always', ctx = {}) {
    const { TEMPLATE_TRANSITION_END } = externalEventsCodes;
    const { CURRENTVIEW, UI } = bindingCodes;
    var template = this.get(name);
    let cache = this.cache;
    let oldPageName;

    for (let tplName in cache) {
      if(cache.hasOwnProperty(tplName)){
        if (tplName === this.selected) {
          oldPageName = tplName;
          cache[tplName].deactivate();
        } else if (name !== tplName) {
          cache[tplName].cache();
        }
      }
    }

    this.selected = name;
    template.activate();

    if(bridgeChannelManager && binding !== CURRENTVIEW && binding !== UI){
      bridgeChannelManager.updateBridgeChannels(oldPageName, name, ctx);
    }

    eventManager.emit(TEMPLATE_TRANSITION_END, template);
  }

  /**
   *
   * remove one template by name.
   * it remove the template from html and from the templates and cache nodes
   *
   * @param {String} templateName the name of the  template to remove
   */
  removeTemplate(templateName) {
    if (this.templates[templateName]) {
      const node = document.querySelector('#' + this.templates[templateName].id);
      const pos = this.locations.indexOf(templateName);
      this.locations.splice(pos, 1);
      node.parentNode.removeChild(node);
      delete this.templates[templateName];
      delete this.cache[templateName];
      this.size--;
    }
  }

  /**
   *
   * remove all templates except initial one and cross component one.
   *
   * @param {String} initialTemplate the name of the initial template
   * @param {String} crossContainerId the name of the cross component template
   */
  removeTemplates(initialTemplate, crossContainerId) {
    for (let templateName in this.templates) {
      if(this.templates.hasOwnProperty(templateName)){
        if (templateName !== initialTemplate && templateName !== crossContainerId) {
          this.removeTemplate(templateName);
        }
      }
    }
  }

  /**
   *
   * remove all children of a template,
   * it is used, for example, for clear cross component template.
   *
   * @param {String} templateName the name of the  template
   */
  removeTemplateChildrens(templateName){
    let template = this.templates[templateName];
    if(template){
      while (template.firstChild) {
        template.removeChild(template.firstChild);
      }
    }
  }
}
